; (function($) {
  var scrollTarget$ = $('section.contact-us'), win$ = jQuery(window), firstInput$ = $('input:first-child'),
    name$    = $('[name="name"]'),
    email$   = $('[name="email"]'),
    message$ = $('[name="message"]'),
    outcome$ = $('.outcome'),
    scrollFn = function(e){
    e.preventDefault();
    win$.scrollTo(scrollTarget$, {
      duration: 800,
      onAfter: function(){
        firstInput$.focus();
      }
    });
  }, sendFn = function(e){
    e.preventDefault();
    $.post('/sendEmail.php', {
        name: name$.val(),
        email: email$.val(),
        message: message$.val(),
        'g-recaptcha-response': $('[name="g-recaptcha-response"]').val()
      })
      .then(function(result){
        console.log('result = ', typeof result);
        var res;
        try{
          res = JSON.parse(result);
          if(res.status === 'ok'){
            console.log('res.status = ok');
            name$.val('');
            email$.val('');
            message$.val('');
            // messaggio inviato con successo
            outcome$.html('Your email was sent successfully');            
          }
        }catch(exception){
          console.log('errore!', exception);
        }finally{
          grecaptcha.reset();
        }
      })
      .fail(function(err){
      console.log('!error: ', err);
      })
    ;
  }
  $('.contactus>a').on('click', scrollFn);
  $('#send').on('click', sendFn);
})(jQuery);




